import { IDiscipline } from "Data/interfaces/Obra/IObraData";
import { IConstructionSiteStageLibrary } from "Data/interfaces/Obra/IObraLibraries";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { IStage } from "Data/interfaces/Obra/IObraStages";

export enum SettingsConfigEnum {
  validateStagesInNomenclature,
  allowOnlyStagesLinkedToDisciplinesInUpload,
  allowOnlyStagesLinkedToDisciplinesInTopics,
}
export interface InputStageProps {
  acronym: string;
  stageName: string;
}

export interface InputStageRequest {
  acronym: string;
  stageName: string;
  isDefault: boolean;
  disciplineIds: number[];
}

export interface IStages {
  csId: number;
  stages: IStage[];
  libraries: IConstructionSiteStageLibrary[];
  disciplines: IDiscipline[];
  obras?: IObraResponse[];
  loading?: boolean;
  listStagesLoading?: boolean;
  listLibrariesLoading?: boolean;
  ValidateStagesInNomenclature: boolean;
  AllowOnlyStagesLinkedToDisciplinesInTopics: boolean;
  AllowOnlyStagesLinkedToDisciplinesInUpload: boolean;
}

export const headerStagesTable = [
  {
    id: '1',
    label: '#',
  },
  {
    id: '2',
    label: 'Sigla',
  },
  {
    id: '3',
    label: 'Etapa',
  },
  {
    id: '4',
    label: 'Disciplinas',
  },
  {
    id: '5',
    label: '',
  },
];
