import { Icon } from '..';
import Button from '../Button';
import { Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { ListagemArquivosActions } from 'Store/ListagemArquivos/ListagemArquivos.actions';

import styles from './ModalDownload.module.scss';
import { ApisEnumLabel, getIconApi, getGifApi } from 'Data/enums/Apis';

type DownloadModalProps = {
  platform: number;
  urlFolder?: string;
  hasFolder?: boolean;
  onTrackDownloadMultiple?: () => void;
};

const Content = ({
  platform,
  urlFolder,
  hasFolder,
  onTrackDownloadMultiple
}: DownloadModalProps) => {
  const dispatch = useDispatch();

  return (
    <div className={styles['Wrapper']}>
      <div className={styles['header']}>
        <div className={styles['iconBox']}>
          <Icon
            icon="cancelar"
            customSize={12}
            color="branco"
            onClick={() =>
              dispatch(
                ListagemArquivosActions.setShowModalDownloadMultipleFiles({
                  isVisible: false
                })
              )
            }
          />
        </div>
        <div className={styles['imageBox']}>
          <img
            src={getGifApi(platform)}
            alt={`Gif de exemplo para download na plataforma do ${ApisEnumLabel[platform]}`}
          />
        </div>
      </div>
      <div className={styles['content']}>
        <Tag color="#3547C8" className={styles['tag']}>
          <Icon icon={getIconApi(platform)} color="branco" customSize={12} />
          Função externa
        </Tag>

        <h4 className={styles['title']}>
          {hasFolder ? 'Download de pastas' : 'Download de múltiplo de arquivos'}
        </h4>
        <p className={styles['description']}>
          {hasFolder
            ? `Para fazer o download de pastas,
            entre na pasta da obra no ${ApisEnumLabel[platform]} e selecione
             as pastas que deseja baixar. Após selecionar as pastas,
             clique com o botão direito do seu mouse e clique em ”Fazer download”.`
            : `Para fazer o download múltiplo de arquivos, 
          entre na pasta da obra no ${ApisEnumLabel[platform]} e selecione
          os arquivos que deseja baixar. Após selecionar os 
          arquivos, clique com o botão direito do seu mouse 
          e clique em ”Fazer download”.`}
        </p>
      </div>

      <Footer
        platform={platform}
        urlFolder={urlFolder}
        onTrackDownloadMultiple={onTrackDownloadMultiple}
      />
    </div>
  );
};

const Footer = ({ platform, urlFolder, onTrackDownloadMultiple }: DownloadModalProps) => (
  <div className={styles['Footer']}>
    <div className={styles['link']}>
      <a
        href="https://www.maletadoengenheiro.com.br/blog"
        target="_blank"
        rel="noopener noreferrer"
      >
        Saiba mais
      </a>
    </div>
    <Button
      type="primary"
      onClick={onTrackDownloadMultiple ? onTrackDownloadMultiple : undefined}
    >
      <a
        className={styles['buttonLink']}
        href={urlFolder}
        target="_blank"
        rel="noopener noreferrer"
      >
        {`Abrir ${ApisEnumLabel[platform]}`}
      </a>
    </Button>
  </div>
);

const DownloadModal = ({
  platform,
  urlFolder,
  onTrackDownloadMultiple
}: DownloadModalProps) => {
  const visible = useSelector(
    (state: IGlobalReducerState) => state.listagemArquivos.showModalDownloadMultipleFiles
  );
  const hasFolder = useSelector(
    (state: IGlobalReducerState) => state.listagemArquivos.hasFolderModalDownload
  );

  if (!visible) {
    return null;
  }

  return (
    <div className={styles['Modal']}>
      <Content
        platform={platform}
        urlFolder={urlFolder}
        onTrackDownloadMultiple={onTrackDownloadMultiple}
        hasFolder={hasFolder}
      />
    </div>
  );
};

export default DownloadModal;
