import { FC, useEffect, useMemo, useState } from "react";
import { Icon, IconName } from "Components/UI";
import Modal from "Components/UI/Modal/Modal";
import { IObraResponse } from "Data/interfaces/Obra/IObraListResponse";
import { Checkbox, ConfigProvider, Spin } from "antd";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import Button from "Components/UI/Button";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ConstructionSiteStageLibraryEnum,
  IConstructionSiteStageLibrary,
  IConstructionSiteStageLibraryItem,
  IConstructionSiteStageLibraryRequest,
} from "Data/interfaces/Obra/IObraLibraries";
import {
  Body,
  BodyContent,
  Children,
  Container,
  Content,
  Footer,
  HeaderContent,
  Hr,
  Left,
  Main,
  MainContent,
  Section,
  Title,
  Top,
} from "./styles";

interface OptionsDefault {
  title: string;
  children: IConstructionSiteStageLibrary[];
}

interface ILibrary {
  visible: boolean;
  csId: number;
  loading?: boolean;
  listLoading?: boolean;
  obras?: IObraResponse[];
  libraries: IConstructionSiteStageLibrary[];
  onSubmit: (values: IConstructionSiteStageLibraryRequest) => void;
  onCancel: () => void;
}

const iconNameTypeEnum: Record<ConstructionSiteStageLibraryEnum, IconName> = {
  [ConstructionSiteStageLibraryEnum.Standard]: 'coordly',
  [ConstructionSiteStageLibraryEnum.MarketStandard]: 'abnt',
};

const Library: FC<ILibrary> = ({
  visible,
  csId,
  loading,
  listLoading,
  obras,
  libraries,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme);

  const [active, setActive] = useState(0);
  const [selecteds, setSelecteds] = useState<number[]>([]);

  const options = useMemo(() => {
    const data: OptionsDefault[] = [];

    if (libraries && libraries.length > 0) {
      const standardLibraries = libraries.filter(lib => lib.Type === ConstructionSiteStageLibraryEnum.Standard);
      const marketLibraries = libraries.filter(lib => lib.Type === ConstructionSiteStageLibraryEnum.MarketStandard);

      if (standardLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Selecione um padrão de etapas',
          children: []
        };

        for (const lib of standardLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }

      if (marketLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Padrões do mercado',
          children: []
        };

        for (const lib of marketLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }
    }

    return data;
  }, [libraries]);

  const optionActive = useMemo(() => {
    return libraries.find(opt => {
      return opt.ConstructionSiteStageLibraryId === active;
    });
  }, [active, libraries]);

  const optionActiveChildren = useMemo(() => {
    let dataDefault: IConstructionSiteStageLibraryItem[] = [];

    if (optionActive && optionActive?.Items && optionActive.Items?.length > 0) {
      dataDefault = optionActive.Items.sort((a, b) => a.Order - b.Order);
    }

    const allOpts: number[] = [];
    for (const item of dataDefault) {
      allOpts.push(item.ConstructionSiteStageLibraryItemId);
    }

    return {
      data: dataDefault,
      dataChildIds: allOpts,
      count: allOpts.length,
    };
  }, [optionActive]);

  const handleSend = () => {
    if (active === 0 || selecteds.length === 0) return;

    const request: IConstructionSiteStageLibraryRequest = {
      constructionSiteId: csId,
      constructionSiteStageLibraryId: active,
      constructionSiteStageLibraryItems: selecteds,
    }
    onSubmit(request);
  }

  const onCheck = (id: number) => {
    setSelecteds(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }

      return [...prev, id];
    })
  };

  const onCheckAll = () => {
    setSelecteds(prev => {
      if (prev.length === optionActiveChildren.count) {
        return [];
      }

      return optionActiveChildren.dataChildIds;
    });
  };

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      const standardLibrary = libraries.find(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.Standard;
      });
      const currentActive = standardLibrary?.ConstructionSiteStageLibraryId ||
        libraries[0].ConstructionSiteStageLibraryId;

      setActive(currentActive);
    }
  }, [libraries]);

  return (
    <Modal
      visible={visible}
      width={750}
      footerButtons={null}
    >
      <Container>
        <Title>
          <span className="textTitle">Biblioteca de etapas</span>
          <Icon
            icon="cancelar"
            customSize={12}
            className="iconTitle"
            onClick={onCancel}
          />
        </Title>

        <Content>
          <Left>
            {options.map((opt, index) => (
              <Section key={opt.title}>
                <span className="title">{opt.title}</span>
                {opt.children.map(item => (
                  <Children
                    isactive={active === item.ConstructionSiteStageLibraryId}
                    onClick={() => setActive(item.ConstructionSiteStageLibraryId)}
                  >
                    {(item.IconName ||
                      item.Type === ConstructionSiteStageLibraryEnum.Standard ||
                      item.Type === ConstructionSiteStageLibraryEnum.MarketStandard) && (
                        <Icon
                          icon={item.IconName || iconNameTypeEnum[item.Type]}
                        />
                      )}
                    {/* {item.Type !== ConstructionSiteStageLibraryEnum.Standard &&
                      item.Type !== ConstructionSiteStageLibraryEnum.MarketStandard && (
                        <GetAvatar
                          name={item.title}
                          shape="circle"
                          size={20}
                          src={item.avatar}
                          thumbSrc={item.avatar}
                          thumbType="mid"
                        />
                      )} */}
                    <span className="titleChildren">{item.Name}</span>
                  </Children>
                ))}
                {index !== options.length - 1 && <Hr />}
              </Section>
            ))}
          </Left>

          <Main>
            {optionActive && (
              <Top>
                <span className="title">{optionActive.Name}</span>
                <span className="description">{optionActive?.Description}</span>
              </Top>
            )}
            <MainContent>
              <ConfigProvider theme={{
                token: {
                  colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
                  colorTextDisabled: theme.colors.primary.onPrimary,
                  colorPrimary: theme.colors.primary.primary
                }
              }}>
                <HeaderContent>
                  <span className="checkbox">
                    <Checkbox
                      checked={selecteds.length === optionActiveChildren.count}
                      indeterminate={
                        selecteds.length > 0 &&
                        selecteds.length !== optionActiveChildren.count
                      }
                      disabled={loading}
                      onClick={onCheckAll}
                    />
                  </span>
                  <span className="columnSigla">Sigla</span>
                  <span className="columnEtapa">Etapa</span>
                </HeaderContent>
                <Body>
                  <BodyContent>
                    {optionActiveChildren.data.map(opt => (
                      <span key={opt.ConstructionSiteStageLibraryItemId} className="row">
                        <span className="checkbox">
                          <Checkbox
                            checked={selecteds.includes(opt.ConstructionSiteStageLibraryItemId)}
                            disabled={loading}
                            onClick={() => onCheck(opt.ConstructionSiteStageLibraryItemId)}
                          />
                        </span>
                        <span className="rightRow">
                          <span className="columnSigla">{opt.Acronym}</span>
                          <span className="columnEtapa">{opt.Name}</span>
                        </span>
                      </span>
                    ))}
                  </BodyContent>
                </Body>
                <Footer>
                  <span className="textLeft">
                    {`${selecteds.length} etapas selecionadas`}
                  </span>
                  <Button
                    type="primary"
                    className="btnFooter"
                    onClick={handleSend}
                  >
                    {loading && (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            rev=""
                            color="white"
                            style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                          />
                        }
                      />
                    )}
                    {loading ? 'Importando...' : 'Importar Etapas'}
                  </Button>
                </Footer>
              </ConfigProvider>
            </MainContent>
          </Main>
        </Content>
      </Container>
    </Modal>
  )
}

export default Library;
