import React, { Dispatch, FC } from 'react';

import styles from './ExclusaoArquivos.module.scss';
import Modal from '../../../../Components/UI/Modal/Modal';
import Button from '../../../../Components/UI/Button';
import ListContainer from '../../../../Components/UI/Containers/ListContainer/ListContainer';
import { iconFileByExtension } from '../../../../Utils/Icons';
import { Icon } from '../../../../Components/UI';
import { sizeFileToInitials } from '../../../../Utils/toolBox';
import { IDeleteFilesRequest, IRestoreObsoleteFiles } from '../../../../Data/interfaces/Obra/IDeleteFilesRequest';
import { IBreadCrumbItem } from 'Data/interfaces/ListagemArquivos/IListFilesResponse';
import { ListagemArquivosActions, ListagemArquivosActionUnion } from 'Store/ListagemArquivos/ListagemArquivos.actions';

export interface IExclusaoArquivos {
  visible?: boolean;
  onCancel?: () => void;
  dispatch: Dispatch<ListagemArquivosActionUnion>;
  isLoading?: boolean;
  deleteFilesRequest: IDeleteFilesComponentRequest;
}

export interface IDeleteFilesComponentRequest {
  filesToDelete: any;
  FolderId: string;
  ObsoleteFolderId?: string;
  ConstructionSiteId: number;
}

const ExclusaoArquivos: FC<IExclusaoArquivos> = ({
  visible,
  onCancel,
  dispatch,
  isLoading,
  deleteFilesRequest,
  }) => {
  const lastObsoleteFiles = deleteFilesRequest.filesToDelete
    .filter((file: any) => (file.Versions && file.Versions?.length))
    .map((obsoleteFile: any) => {
      const versions = obsoleteFile.Versions;
      return versions && versions.length ? versions[0] : null;
    });
  const hasObsoletFiles = lastObsoleteFiles.length > 0;

  const removeFiles = (obsoleteFiles?: IRestoreObsoleteFiles[]) => {
    const FilesId = [];
    let onlyFolder = true;
    let hasFolder = false;
    for (const file of deleteFilesRequest.filesToDelete) {
      FilesId.push(file.Identifier.ApiId);
      if (file.IsFolder) {
        hasFolder = true;
      } else {
        onlyFolder = false;
      }
    }

    const request: IDeleteFilesRequest = {
      ConstructionSiteId: deleteFilesRequest.ConstructionSiteId,
      FilesId,
      RestoreObsoleteFiles: obsoleteFiles || [],
      FolderId: deleteFilesRequest.FolderId,
      hasFolder,
      onlyFolder,
    };

    dispatch(ListagemArquivosActions.deleteFiles(request));
  };

  const deleteAndRecoverObsoleteFiles = () => {
    const obsoletFilesRestored: IRestoreObsoleteFiles[] = lastObsoleteFiles.map((obsoletFile: any) => ({
      FileId: obsoletFile?.Identifier.ApiId || '',
      FolderId: deleteFilesRequest.FolderId,
      ObsoleteFolderId: deleteFilesRequest.ObsoleteFolderId || '',
    }));
    removeFiles(obsoletFilesRestored);
  };

  const footerButtons = (<>
    {
      hasObsoletFiles
      ? (<Button onClick={() => removeFiles()} loading={isLoading}>
        Apenas excluir versão atual
      </Button>)
      : null
    }
    <Button
      type='primary'
      loading={isLoading}
      onClick={() => hasObsoletFiles ? deleteAndRecoverObsoleteFiles() : removeFiles()}>
      {
        hasObsoletFiles
        ? 'Excluir a versão atual e restaurar versão anterior'
        : 'Sim, excluir o arquivo permanentemente'
      }
    </Button>
  </>);

  return (
    <Modal
      width={hasObsoletFiles ? 798 : 646}
      title='Exclusão de arquivo'
      visible={visible}
      onCancel={onCancel}
      footerButtons={footerButtons}>
      <div className={styles['textWrap']} >
        <div className={styles['margin']} >
          Atenção: a exclusão de arquivos é realizada de forma permanente, não sendo possível desfazer a ação.
        </div>
        {
          hasObsoletFiles
          ? (<>
              <span className={styles['title-list']}>
                Também verificamos que este arquivo possui uma versão anterior:
              </span>
              {
                lastObsoleteFiles?.map((obsoleteFile: any, index: number) => (
                  <ListContainer height={51} key={`obsoleteFile${index}`} className={styles['obsoleteFileWrap']} >
                    <Icon
                      color='cinzaPadrao'
                      icon={iconFileByExtension(obsoleteFile?.Extension || '')}
                      customSize={18} />
                      <span className={styles['title']} >{obsoleteFile?.Name}</span>
                      <span className={styles['padrigth']} >{obsoleteFile?.Extension}</span>
                      <span className={styles['padrigth']} >{sizeFileToInitials(obsoleteFile?.SizeInBytes || 0)}</span>
                      <span>{new Date(obsoleteFile?.LastUpdate || '').toLocaleDateString()}</span>
                  </ListContainer>
                ))
              }
            </>)
          : (<div>Deseja continuar?</div>)
        }
      </div>
    </Modal>
  );
};

export default ExclusaoArquivos;
