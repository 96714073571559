import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};
  margin-top: 12px;

  &+& {
    margin-top: 6px;
  }

  .row1 {
    width: 41px;
    margin-left: 16px;
  }

  .row2 {
    width: 62px;
    margin-left: 12px;
  }

  .row3 {
    display: flex;
    width: 40%;
    margin-left: 12px;
  }

  .row4 {
    display: flex;
    width: 40%;
    margin-left: 12px;

    .emptyText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    }
  }

  .row5 {
    width: 100px;
    padding-left: 12px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .actionBtn {
      height: 32px !important;
      width: 32px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      padding: 0 !important;

      .actionIcon {
        color: ${({ theme }) => theme.colors.secondary.onSecondary} !important;
      }

      &+.actionBtn {
        margin-left: 6px !important;
      }
    }
  }

  .rowText {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
  
  .tagDefault {
    border-radius: 4px;
    padding: 2px;
    margin-left: 6px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary};

    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }
`;

interface IDisciplineContainer {
  istext?: boolean;
}

export const DisciplineContainer = styled.span<IDisciplineContainer>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &+.disciplineIconContainer {
    margin-left: 2px;
  }

  .disciplineIcon {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;  
  }

  .textTotal {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant};
    margin-left: 2px;
  }

  :hover {
    border-radius: 4px;
    background-color: ${({ istext, theme }) => istext
    ? 'transparent'
    : `color-mix(in srgb, ${theme.colors.surface.onSurfaceHover}, ${theme.colors.surface.surface})`} !important;
  }
`;
