import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 485px;
  max-height: 485px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.surface};
`;

export const Title = styled.div`
  width: 100%;
  min-height: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface.surfaceOutline};
  background-color: ${({ theme }) => theme.colors.surface.surface};

  .textTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.surface.onSurface};
  }

  .iconTitle {
    color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0 24px;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .formFieldsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .containerAcronym {
      width: 100px;
      
      .fieldAcronym {
        width: 100px;
        margin-top: 5px;
      }
    }

    .containerStageName {
      width: 286px;
      margin-left: 16px;

      .fieldStageName {
        width: 286px;
        margin-top: 5px;
      }
    }

    .fieldLabel {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      
      .required {
        color: ${({ theme }) => theme.colors.danger.danger};
      }
    }
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .titleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    .titleSearch {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
    }

    .titleBtn {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.primary.primary};
      cursor: pointer;
    }
  }

  .searchBox {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    margin-top: 12px;
    
    .search {
      background-color: ${({ theme }) => theme.colors.secondary.secondary} !important;
    }
  }
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  max-height: 263px;
  margin-top: 6px;
  padding-bottom: 16px;
  overflow-y: auto;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .btnContainerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;

    .leftText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.surface.onSurface};
      margin-left: 12px;
    }

    .iconInfo {
      color: ${({ theme }) => theme.colors.surface.onSurfaceVariant} !important;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .textBtn {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondary.onSecondary};
  }
`;
