import { FC, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import createSchema from "./CreateForm.schema"
import addItemSchema from "./AddItem.schema"
import Modal from "Components/UI/Modal/Modal";
import Form from "Components/UI/Form/Form";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import { AdmActions } from "Store/Adm/Adm.actions";
import { IGlobalReducerState } from "Store/Base/interface/IGlobalReducerState";
import { Checkbox } from "antd";
import SelectForm, { IOptionData } from "Components/UI/SelectForm";
import {
  ConstructionSiteStageLibraryEnum,
  IConstructionSiteStageLibrary,
  ICreateStageLibraryRequest,
  IStageLibraryItem,
  IUpdateStageLibraryRequest,
} from "Data/interfaces/Obra/IObraLibraries";
import { v4 as uuid } from "uuid";
import { Icon, iconClasses, IconName } from "Components/UI";
import { isObjectEmpty } from "Utils/ObjectUtils";
import {
  Card,
  Content,
  FormContainer,
  FormWrapper,
  HeaderContent,
  Main,
} from "./styles";
import IconPicker from "Components/UI/IconPicker";

interface InputLibProps {
  Name: string;
  Description: string;
}

interface InputLibItemProps {
  Acronym: string;
  StageName: string;
}

interface ICreateForm {
  visible?: boolean;
  editLib: IConstructionSiteStageLibrary | null;
  onClose: () => void;
}

const headerStagesTable = [
  {
    id: '1',
    label: 'Sigla',
  },
  {
    id: '2',
    label: 'Etapa',
  },
];

const CreateForm: FC<Props> = ({
  editLib,
  visible,
  isLoading,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [type, setType] = useState<ConstructionSiteStageLibraryEnum | null>(
    null
  );
  const [dataItems, setDataItems] = useState<Record<string, IStageLibraryItem>>({});
  const [libName, setLibName] = useState('');
  const [libDescription, setLibDescription] = useState('');
  const [acronym, setAcronym] = useState('');
  const [stageName, setStageName] = useState('');
  const [iconName, setIconName] = useState<IconName>(editLib?.IconName || 'coordly');
  const [checked, setChecked] = useState(false);

  const submit = (values: InputLibProps) => {
    if (values && type !== null) {
      setIsSending(true);

      const items = isObjectEmpty(dataItems)
        ? []
        : Object.values(dataItems);
      const request: ICreateStageLibraryRequest = {
        name: values.Name,
        description: values.Description,
        type: type,
        iconName: iconName,
        items,
      }
      if (!!editLib) {
        const upRequest: IUpdateStageLibraryRequest = {
          stageLibraryId: editLib.ConstructionSiteStageLibraryId,
          stageLibrary: request,
        }
        dispatch(AdmActions.updateLibrary(upRequest));
      } else {
        dispatch(AdmActions.createLibrary(request));
      }
    }

    setIsSubmit(false);
  }

  const addItem = (values: InputLibItemProps) => {
    if (values) {
      const id = uuid();

      setDataItems(prev => {
        if (checked) {
          Object.keys(prev).forEach(key => {
            prev[key].isDefault = false;
          });
        }

        prev[id] = {
          name: values.StageName,
          acronym: values.Acronym,
          isDefault: checked,
        };

        return { ...prev };
      })
    }
  }

  const removeItem = (id: string) => {
    setDataItems(prev => {
      if (prev.hasOwnProperty(id)) {
        delete prev[id];
      }

      return { ...prev };
    })
  }

  const onCloseAux = () => {
    onClose();
  }

  const iconOptions = useMemo(() => {
    return Object.keys(iconClasses) as IconName[];
  }, []);

  const optionsSelect = [
    { value: 0, description: 'Padrão Coordly' },
    { value: 1, description: 'Padrão Mercado' },
  ] as IOptionData[];

  const buttons = (
    <div>
      <Button
        onClick={onCloseAux}
      >
        <span>Cancelar</span>
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={isLoading || type === null || isObjectEmpty(dataItems)}
        onClick={() => setIsSubmit(true)}
      >
        <span>Confirmar</span>
      </Button>
    </div>
  );

  useEffect(() => {
    if (!!editLib) {
      setLibName(editLib.Name);
      setLibDescription(editLib?.Description || '');
      setType(editLib.Type);
      setIconName(editLib?.IconName || 'coordly');
      const newDataItems: Record<string, IStageLibraryItem> = {};
      editLib.Items.forEach(item => {
        const id = uuid();
        newDataItems[id] = {
          name: item.Name,
          acronym: item.Acronym,
          isDefault: item.IsDefault,
        }
      });
      setDataItems(newDataItems);
    }
  }, [editLib]);

  useEffect(() => {
    if (isSending && !isLoading) {
      setIsSending(false);
      onCloseAux();
    }
  }, [isSending, isLoading]);

  return (
    <Modal
      title={!!editLib ? 'Editar Biblioteca' : 'Criar Nova Biblioteca'}
      visible={visible}
      footerButtons={buttons}
      onCancel={onCloseAux}
    >
      <FormWrapper>
        <FormContainer>
          <Form
            schema={createSchema}
            onSubmit={submit}
            isSubmited={isSubmit}
            resetForm={true}
            initialValues={{
              Name: editLib?.Name || '',
              Description: editLib?.Description || '',
            }}
          >
            <div className="rowName">
              <Field
                label="Nome Biblioteca"
                name="Name"
                value={libName}
                onCustomChange={setLibName}
              />
              <SelectForm
                onSelect={(value: any) => setType(value)}
                className="selectRole"
                placeholder="Tipo Biblioteca"
                options={optionsSelect}
                name="Type"
                defaultValue={type}
                style={{ width: 160 }}
              />
            </div>
            <div className="rowIcon">
              <IconPicker
                options={iconOptions}
                value={iconName}
                onChange={setIconName}
              />
              {iconName && (
                <div className="iconContainer">
                  <Icon
                    icon={iconName}
                    customSize={18}
                    className="icon"
                  />
                </div>
              )}
            </div>
            <Field
              label="Descrição Biblioteca"
              name="Description"
              value={libDescription}
              onCustomChange={setLibDescription}
            />
          </Form>

          <Form
            schema={addItemSchema}
            onSubmit={addItem}
            resetForm={true}
            initialValues={{ Acronym: '', StageName: '' }}
          >
            <Content>
              <span className="checkContainer">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(prev => !prev)}
                  disabled={isLoading}
                />
                <span className="checkText">Padrão</span>
              </span>
              <span className="containerField">
                <Field
                  name="Acronym"
                  label="Sigla"
                  className="fieldAcronym"
                  value={acronym}
                  onCustomChange={setAcronym}
                />
              </span>
              <span className="containerField">
                <Field
                  name="StageName"
                  label="Nome da Etapa"
                  className="fieldStageName"
                  value={stageName}
                  onCustomChange={setStageName}
                />
              </span>
              <Button
                type="primary"
                htmlType="submit"
                className="addButton"
              >
                <Icon
                  icon="adicionar"
                  customSize={16}
                  className="addIconButton"
                />
              </Button>
            </Content>
          </Form>

          <HeaderContent>
            {headerStagesTable.map(head => (
              <div key={`h-p-t-${head.id}`} className={`column${head.id}`}>
                <span className="columnText">{head.label}</span>
              </div>
            ))}
          </HeaderContent>
          <Main>
            {!isObjectEmpty(dataItems) && (
              <>
                {Object.entries(dataItems).map(([id, item]) => (
                  <Card key={id}>
                    <span className="row1">{item.acronym}</span>
                    <span className="row2">
                      <span>
                        {item.name}
                        {item.isDefault && (
                          <span className="tagDefault">Padrão</span>
                        )}
                      </span>
                      <Button
                        type="text"
                        htmlType="submit"
                        onClick={() => removeItem(id)}
                        className="removeButton"
                      >
                        <Icon
                          icon="excluir"
                          customSize={14}
                          className="removeIconButton"
                        />
                      </Button>
                    </span>
                  </Card>
                ))}
              </>
            )}
          </Main>
        </FormContainer>
      </FormWrapper>
    </Modal>
  )
}

const mapState = (state: IGlobalReducerState) => ({
  ...state.adm
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ICreateForm;

export default connector(CreateForm);