import { CSSProperties, FC, useEffect, useState } from 'react';
import ListContainer from '../Containers/ListContainer/ListContainer';
import { Icon } from '../Icon';
import Field from '../Field';
import Form from '../Form/Form';
import schema, { createSchema } from './UploadFiles.schema';
import TextArea from '../Textarea';
import {
  INomenclatureData,
  VersionFieldEnumLabel,
  SeparatorsData,
} from '../../../Data/interfaces/Nomenclatura/INomenclatureData';
import { IUploadFileData } from '../../../Data/interfaces/Upload/IUploadFileData';
import { sizeFileRangeToInitials } from '../../../Utils/toolBox';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { iconFileByFilename } from '../../../Utils/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { ListagemArquivosActions } from '../../../Store/ListagemArquivos/ListagemArquivos.actions';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RadioButtons from '../RadioButtons';
import { NomenclaturaActions } from '../../../Store/Nomenclatura/Nomenclatura.actions';
import { getTheme } from 'Store/MultiDomain/MultiDomain.selector';
import { history } from 'Store';
import {
  ConfirmButton,
  FileItemWrapper,
  FilenameAndEditWrapper,
  FormWrapper,
  NewFileNameOptionsWrap,
  NomenclatureFieldsWrapper,
  OpenOriginFolder,
  RemoveFileButton,
} from './styles';

export interface INewUploadFileItem {
  index: number;
  uploadFile: IUploadFileData;
  nomenclature: INomenclatureData;
  removeFile: (index: number) => void;
  changeFilename: (index: number, name: string) => void;
  isLoading: boolean;
  testShowEdit?: boolean;
}

const NewUploadFileItem: FC<INewUploadFileItem> = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector(getTheme);

  const [isShowEdit, setIsShowEdit] = useState(
    props.testShowEdit ? props.testShowEdit : false);
  const [newNameFileExistSelected, setNewNameFileExistSelected] = useState<string | number | undefined>();

  const showEdit = () => setIsShowEdit(true);

  const checkUpload = props.uploadFile?.checkUploadFileInfo;

  useEffect(() => {
    if (checkUpload) {
      setNewNameFileExistSelected(checkUpload?.FileNameNewRevision);
    }
  }, [checkUpload]);

  if (!props.nomenclature.VersionFields) {
    dispatch(NomenclaturaActions.getNomenclature());
  }

  const hasNomenclatureError = () => {
    return checkUpload && checkUpload.HasInconsistencia;
  };

  const getEditForms = () => {
    if (isShowEdit && hasNomenclatureError() && checkUpload && checkUpload.HasInconsistencia) {
      return nomenclatureForm;
    }

    if (checkUpload && checkUpload.FileNameExist) {
      return existFileForm;
    }

    if (props.uploadFile.updateFileInfo && !props.uploadFile.updateFileInfo.finished) {
      return descriptionForm;
    }

    return '';
  };

  const nomenclatureSubmit = (data: any) => {
    const fields = Object.keys(data);
    let filename = '';
    for (let i = 0; i < fields.length / 2; i++) {
      filename += `${data[`field${i}`]}${data[`separator${i}`]}`;
    }
    const splittedValues = props.uploadFile.file.name.split('.');
    const extension = splittedValues[splittedValues.length - 1];
    filename = `${filename.slice(0, -1)}.${extension}`;

    props.changeFilename(props.index, filename);
  };

  const getWidthInput = (label: string) => {
    const width = label.length * 8.5;
    return width < 90 ? undefined : { minWidth: width } as CSSProperties;
  }

  const existFileForm = (
    <NewFileNameOptionsWrap>
      <div className="titleButtonWrap">
        <span className="existFileTitle">
          Nome do arquivo já existe, selecione uma opção:
        </span>
        <ConfirmButton
          type='submit'
          isnewfilename="true"
          onClick={() => props.changeFilename(props.index, String(newNameFileExistSelected))}
        >
          <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
        </ConfirmButton>
      </div>
      <div className="radioButtonsWrap">
        <RadioButtons
          options={[
            {
              value: checkUpload?.FileNameNewRevision || '',
              label: (
                <div className="newFileNameOption">
                  <div>{checkUpload?.FileNameNewRevision}</div>
                  <div className="titleOption">Nova revisão</div>
                </div>
              ),
            },
            {
              value: checkUpload?.FileNameCopy || '',
              label: (
                <div className="newFileNameOption">
                  <div>{checkUpload?.FileNameCopy}</div>
                  <div className="titleOption">Cópia</div>
                </div>
              ),
            },
          ]}
          onSelect={setNewNameFileExistSelected}
          defaultValue={checkUpload?.FileNameNewRevision} />
      </div>
    </NewFileNameOptionsWrap>
  );

  const nomenclatureForm = (
    <Form onSubmit={nomenclatureSubmit} schema={createSchema(props.nomenclature.VersionFields)}>
      {(propsField: any) => (
        <FormWrapper>
          <NomenclatureFieldsWrapper isstory={props.testShowEdit}>
            {props.nomenclature.VersionFields.map((field, index) => (
              <span
                key={`fields${index}`}
                style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')}
                className="fieldsWrapper"
              >
                <Field
                  key={`field${index}`}
                  name={`field${field.Ordem}`}
                  defaultValue={checkUpload?.Division[index]}
                  label={field.CampoPersonalizado
                    || VersionFieldEnumLabel.get(field.CampoVersaoId)
                    || ''}
                  style={getWidthInput(field.CampoPersonalizado || VersionFieldEnumLabel.get(field.CampoVersaoId) || '')}
                  className="field"
                  height="30px"
                />
                <Field
                  key={`separator${index}`}
                  name={`separator${field.Ordem}`}
                  label=''
                  value={SeparatorsData.get(field.Separador)?.Value}
                  type='hidden'
                  height="30px"
                />
              </span>
            ))}
          </NomenclatureFieldsWrapper>
          <ConfirmButton
            type='submit'
            disabled={propsField.hasValidationErrors}
          >
            <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
          </ConfirmButton>
        </FormWrapper>
      )}
    </Form>
  );

  const descriptionSubmit = (data: any) => {
    if (props.uploadFile.updateFileInfo) {
      if (!data.description) {
        props.uploadFile.updateFileInfo.notShowToast = true;
      }
      props.uploadFile.updateFileInfo.Description = data.description;
      dispatch(ListagemArquivosActions.updateFileInfo([props.uploadFile.updateFileInfo]));
    };
  }

  const descriptionForm = (
    <Form onSubmit={descriptionSubmit} schema={schema}>
      <FormWrapper isstory={props.testShowEdit} isdescription>
        <TextArea
          name='description'
          label='Descrição do arquivo'
          placeholder='Insira uma descrição para o arquivo'
          style={{ height: 91 }}
        />
        <ConfirmButton type='submit' isdescription="true">
          <Icon icon='confirmacao' customSize={26} className="confirmIcon" />
        </ConfirmButton>
      </FormWrapper>
    </Form>
  );

  const handleOpenFolder = (uploadFile: IUploadFileData) => {
    if (!uploadFile.uploadBreadcrumbFile) return;

    history.push(`/obras/filelist/${uploadFile.csId}/${uploadFile.uploadBreadcrumbFile.fileParentId}${uploadFile.uploadBreadcrumbFile?.fileParentBreadcrumbId ? `?breadCrumbId=${uploadFile.uploadBreadcrumbFile.fileParentBreadcrumbId}` : ''}`);
  };

  if (props.uploadFile.removed) {
    return <></>;
  }

  return (
    <ListContainer height={'auto'}>
      <FileItemWrapper>
        <div className="fileItemDescriptionWrapper">
          <Icon
            icon={iconFileByFilename(props.uploadFile.file.name)}
            color={hasNomenclatureError() ? 'vermelho' : 'cinzaPadrao'}
            customSize={24} />
          <FilenameAndEditWrapper showedit={`${props.uploadFile.updateFileInfo && !props.uploadFile.updateFileInfo.finished}`}>
            <div className="filenameWrapper">
              <div className="fileName">
                {hasNomenclatureError() || props.uploadFile.uploadError ? (
                  <>
                    <span className="hasError">
                      {props.uploadFile.newFileName || props.uploadFile.file.name}
                    </span>
                    {hasNomenclatureError() ? (
                      <span className="hasError">Nomeclatura inválida</span>
                    ) : (
                      <span className="hasError">Erro ao enviar o arquivo</span>
                    )}
                  </>
                ) : (
                  <span>
                    {props.uploadFile.newFileName || props.uploadFile.file.name}
                  </span>
                )}

              </div>
              {props.uploadFile.isSending ? (
                <span className="fileSize">
                  {sizeFileRangeToInitials(props.uploadFile.sentSize, props.uploadFile.totalSize)}
                </span>
              ) : ''}
            </div>
            {hasNomenclatureError() && !isShowEdit && (
              <div className="editButton" onClick={showEdit}>
                <Icon icon='editar' color='primary' />
              </div>
            )}
          </FilenameAndEditWrapper>
          <div className="fileItemFooterWrapper">
            {hasNomenclatureError() && isShowEdit && (
              <RemoveFileButton onClick={() => props.removeFile(props.index)}>
                <Icon icon='cancelar' color='cinzaPadrao' customSize={10} />
              </RemoveFileButton>
            )}
            {props.uploadFile.isSending && (!props.uploadFile.updateFileInfo?.finished || props.uploadFile.updateFileInfo?.finished === undefined) ? (
              <CircularProgressbar
                className="loading"
                strokeWidth={14}
                styles={buildStyles({
                  pathColor: theme.colors.primary.primary,
                  trailColor: theme.colors.secondary.secondary
                })}
                value={props.uploadFile.sentSize / props.uploadFile.totalSize}
                maxValue={1}
              />
            ) : <></>}
            {props.uploadFile.updateFileInfo?.finished ? (
              <OpenOriginFolder
                onClick={() => handleOpenFolder(props.uploadFile)}
              >
                <Icon icon='arquivar' customSize={11} className="openCloudServiceIcon" />
              </OpenOriginFolder>
            ) : ''}
            {!(props.uploadFile.isSending || props.uploadFile.updateFileInfo?.finished || props.uploadFile.uploadCompleted || hasNomenclatureError() || checkUpload?.FileNameExist || props.uploadFile.uploadError) ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    rev=""
                    style={{ fontSize: 20 }}
                    color={theme.colors.primary.primary}
                    spin
                  />
                }
              />
            ) : ''}
          </div>
        </div>

        {getEditForms()}
      </FileItemWrapper>
    </ListContainer>
  );
};

export default NewUploadFileItem;
