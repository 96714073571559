import { Dispatch, FC, SetStateAction } from 'react';
import * as H from 'history';
import { IRoute } from 'Routes/AuthenticatedRoutes/MainSwitch';
import iconMenuPremium from 'Static/images/icon-menu-premium.png';
import Badge from 'Components/UI/Badge';
import Ellipsis from 'Components/UI/Ellipsis';
import useHistoryPush from 'Hooks/useHistoryPush';
import { IUserInforResponse } from 'Data/interfaces/Auth/IUserInfoResponse';
import { useFeatureFlag } from 'Hooks/useFeatureFlag';
import { IconName, Icon } from '../../../UI';
import { WrapperStyled } from './styles';
export interface IMenuItem {
  icon?: IconName;
  title: string;
  path: string;
  children?: IRoute[];
  isChildren?: boolean;
  location?: H.Location<H.LocationState>;
  isChange?: boolean;
  isCollapsed: boolean;
  openFirstItemDefault?: boolean;
  toggleMenuContext?: boolean;
  showIconPremium?: boolean,
  existNotification?: boolean,
  valueConstructionsNotification?: number;
  onCounter: Dispatch<SetStateAction<number>>;
  onOpenList: Dispatch<SetStateAction<boolean>>;
  childrenUserInfo?: IUserInforResponse;
  childrenPlanFree?: boolean;
}

const MenuItem: FC<IMenuItem> = (
  {
    icon,
    title,
    path,
    children,
    isChildren,
    location,
    isChange,
    isCollapsed,
    openFirstItemDefault = true,
    toggleMenuContext,
    showIconPremium,
    existNotification,
    valueConstructionsNotification = 0,
    onCounter,
    onOpenList,
    childrenPlanFree,
    childrenUserInfo,
  },
) => {
  const { historyPush } = useHistoryPush();

  const multiDomainFeature = useFeatureFlag('multi-domain-feature');
  const multiDomainFeatureEnabled = multiDomainFeature.enabled && multiDomainFeature.value === 'test';

  const handleRedirect = () => {
    onOpenList(false);
    if (children && openFirstItemDefault) {
      historyPush(children[0].path);
    } else {
      if (path) {
        historyPush(path);
      }
    }
  };

  const isActive = (): boolean => {
    if (path === (location && location.pathname)) {
      return true;
    }

    if (children && (location && location.pathname)?.indexOf(path) !== -1) {
      return true;
    }

    return false;
  };

  const active = isActive();

  return (
    <WrapperStyled
      active={`${active}`}
      isCollapsed={`${isCollapsed}`}
      isChildren={`${isChildren}`}
    >
      <div className="defaultMenuItem"
        onClick={() => handleRedirect()}
      >
        <Icon
          icon={icon || (active ? 'bolinhaSelecionada' : 'bolinhaNaoSelecionada')}
          customSize={isCollapsed ? 12 : 14}
          className="defaultIcon"
        />

        <p className="titleMenu">
          <Ellipsis phrase={title} maxLength={25}></Ellipsis>
        </p>

        {(children && !isCollapsed && !showIconPremium) && (
          <span className="defaultIconToggleWrap">
            <Icon
              icon="avancar"
              customSize={10}
              className="defaultIconChildren"
            />
          </span>
        )}
        {
          showIconPremium && !isCollapsed
            ? <img src={iconMenuPremium} className="iconPremium" alt='Premium' />
            : null
        }
        {existNotification && !isCollapsed && valueConstructionsNotification > 0 ? (
          <Badge
            type="square"
            value={valueConstructionsNotification}
            style={{ marginRight: 4 }}
          />
        ) : (
          <></>
        )}
      </div>
      {children && !isCollapsed && active && !showIconPremium && children
        .filter(props => {
          if (
            !multiDomainFeatureEnabled &&
            props.path === '/config/personalization'
          ) {
            return false;
          }
          if (
            !props.isPermissionUser ||
            (props.isPermissionUser && props.isPermissionUser.length <= 0)
          ) {
            return true;
          }
          if (
            childrenUserInfo?.CurrentRoleFk &&
            props.isPermissionUser &&
            props.isPermissionUser.length > 0 &&
            props.isPermissionUser.includes(childrenUserInfo.CurrentRoleFk)
          ) {
            return true;
          }
          return false;
        })
        .map((props, i) => (
          <MenuItem
            key={`item-menu-${i}-3`}
            location={location}
            isChildren
            onCounter={onCounter}
            onOpenList={onOpenList}
            title={props.title ?? ''}
            path={props.path}
            children={props.children as IRoute[]}
            isChange={props.isChange}
            isCollapsed={isCollapsed}
            showIconPremium={props.isPremium && childrenPlanFree}
            existNotification={props.existNotification}
            valueConstructionsNotification={valueConstructionsNotification}
            childrenPlanFree={childrenPlanFree}
            childrenUserInfo={childrenUserInfo}
          />
        ))
      }
    </WrapperStyled>
  );
};

export default MenuItem;
