import { FC, CSSProperties } from 'react';
import { Field as FieldReact } from 'react-final-form';
import { IconName, Icon } from '../Icon';
import { valueType } from 'antd/lib/statistic/utils';
import { LabelInput } from './styles';

export interface IField {
  label: string;
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  icon?: IconName;
  type?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  onInput?: (value: string) => void;
  onChange?: (e?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCustomChange?: (value: string) => void;
  value?: string;
  defaultValue?: string | number | undefined;
  parse?: (value: any, name: string) => valueType;
  maxLenght?: number;
  typeInput?: string;
  outError?: string;
  height?: string;
}

const hasError = (meta: any, disabled?: boolean) =>
  !disabled && meta.invalid && meta.touched;

const Field: FC<IField> = ({
  label,
  style,
  className,
  name,
  defaultValue,
  icon,
  type = 'text',
  placeholder = label,
  onFocus,
  onBlur,
  onInput,
  disabled,
  autoFocus,
  value,
  onChange,
  onCustomChange,
  parse,
  maxLenght,
  typeInput,
  outError,
  height,
}) => {
  return (
    <FieldReact
      name={name}
      type={type}
      defaultValue={defaultValue}
      initialValue={value}
      parse={parse}
      typeInput={typeInput}
    >
      {!typeInput
        ? (props) => (
          <LabelInput
            hidden={type === 'hidden'}
            className={className || ''}
            hasicon={!!icon}
            isdisabled={disabled}
            invalid={hasError(props.meta, disabled) && !props.meta.active}
            style={style}
            height={height}
          >
            {icon ? <Icon icon={icon} className="fieldIcon" /> : ''}
            <input
              {...props.input}
              disabled={disabled}
              maxLength={maxLenght}
              placeholder={placeholder}
              autoFocus={autoFocus}
              className="input"
              onInput={(e: any) => onInput && onInput(e.target.value)}
              onChangeCapture={(e) => {
                onChange && onChange(e);
                onCustomChange && onCustomChange(e.currentTarget.value)
              }}
            />
            <span className="label">{label}</span>
            <div className="errorInput errorCustomInput">
              {hasError(props.meta, disabled) ? props.meta.error : ''}
              {!hasError(props.meta, disabled) && outError ? outError : ''}
            </div>
            {props.meta.active ? onFocus && onFocus() : onBlur && onBlur()}
          </LabelInput>
        )
        : (props) => (
          <LabelInput
            hidden={type === 'hidden'}
            className={className || ''}
            hasicon={!!icon}
            isdisabled={disabled}
            invalid={hasError(props.meta, disabled) && !props.meta.active}
            style={style}
            height={height}
          >
            {icon ? <Icon icon={icon} className="fieldIcon" /> : ''}
            <textarea
              {...props.input}
              disabled={disabled}
              maxLength={maxLenght}
              placeholder={placeholder}
              autoFocus={autoFocus}
              className="input"
              onInput={(e: any) => onInput && onInput(e.target.value)}
              onChangeCapture={onChange}
            />
            <span className="label">{label}</span>
            <div className="errorInput errorCustomInput">
              {hasError(props.meta, disabled) ? props.meta.error : ''}
            </div>
            {props.meta.active ? onFocus && onFocus() : onBlur && onBlur()}
          </LabelInput>
        )}
    </FieldReact>
  );
};

export default Field;
